const experiences = [
  {
    works: [
      {
        name: "Html and Css",
        description: "by muazim",
        link:"https://github.com/muazimmaqbool/HTML-and-CSS--basic-to-advance"
      },
      {
        name: "Hands On SASS",
        description: "by muazim",
        link:"https://github.com/muazimmaqbool/Hands-on-SASS"
      },
    ],
  },
  {
    works: [
      {
        name: "Javascript",
        description: "by muazim",
        link:"https://github.com/muazimmaqbool/Javascript-from-scratch"
      },
      {
        name: "JSON Tutorials",
        description: "by muazim",
        link:"https://github.com/muazimmaqbool/Json-tutorials"
      },
    ],
  },
  {
    works: [
      {
        name: "Reactjs",
        description: "by muazim",
        link:"https://github.com/muazimmaqbool/Reactjs-with-Me"
      },
      {
        name: "ReactNative",
        description: "by muazim",
        link:"https://github.com/muazimmaqbool/React-Native-Tutorials"
      },
    ],
  },
  {
    works: [
      {
        name: "ReactNative Navigation",
        description: "by muazim",
        link:"https://github.com/muazimmaqbool/ReactNative_Navigation"
      },
      {
        name: "Nodejs",
        description: "by muazim",
        link:"https://github.com/muazimmaqbool/NodeJs"
      },
    ],
  },
];
export default experiences;
